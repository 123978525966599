<template>
  <div v-loading="loading" class="section-block">
    <SectionTitle title="第三方儲值金顯示名稱設定" @edit="modal.edit = true" />

    <BaseElForm label-position="left" label-width="200px">
      <BaseElFormItem v-for="field in displayData" :key="field.label" :label="field.label">
        {{ field.value }}
      </BaseElFormItem>
    </BaseElForm>

    <EditExternalWalletDisplaySettingsModal
      v-if="modal.edit"
      :configData="configData"
      @close="modal.edit = false"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import { ref, computed, defineComponent, reactive, onBeforeMount } from 'vue'
import EditExternalWalletDisplaySettingsModal from './EditExternalWalletDisplaySettingsModal.vue'
import { GetExternalWalletConfig } from '@/api/externalWallet'
import { externalWalletSourceConfig } from '@/config/externalWallet'
import { get } from 'lodash'
import { useShop } from '@/use/shop'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'ExternalWalletDisplaySettings',
  components: { EditExternalWalletDisplaySettingsModal },
  setup (props) {
    const { shopId } = useShop()
    const { checkAction } = usePermissions()
    const loading = ref(false)
    const configData = ref({})
    const modal = reactive({
      edit: false,
    })

    const displayData = computed(() => {
      return [
        { label: '儲值金來源', value: get(configData.value, 'source') ? selectSource(get(configData.value, 'source')) : '-' },
        { label: '顯示名稱', value: get(configData.value, 'displayName', '-') },
      ]
    })
    const selectSource = (source) => {
      return get(externalWalletSourceConfig, `${source}.label`)
    }
    const refresh = async () => {
      loading.value = true
      const [res, err] = await GetExternalWalletConfig({ shopId: shopId.value })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      configData.value = res
    }

    onBeforeMount(async () => {
      if (checkAction('admin.externalWallet.page')) refresh()
    })

    return { modal, displayData, configData, loading, refresh }
  },
})
</script>

<style lang="postcss" scoped>

</style>
