<template>
  <div>
    <PageTitle
      title="第三方模組顯示名稱設定"
      icon="chevron_left"
      hideBtn
      @iconClick="$router.push({name: 'Parameters'})"
    />
    <div class="grid gap-[32px]">
      <ExternalPointDisplaySettings v-if="useExternalPoint" />
      <ExternalWalletDisplaySettings v-if="useExternalWallet" />
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import ExternalPointDisplaySettings from './components/ExternalPointDisplaySettings.vue'
import ExternalWalletDisplaySettings from './components/ExternalWalletDisplaySettings.vue'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'ExternalPoint',
  components: { PageTitle, ExternalPointDisplaySettings, ExternalWalletDisplaySettings },
  setup (props) {
    const { checkActionList } = usePermissions()
    const useExternalPoint = computed(() =>
      checkActionList([
        'admin.externalPointConfig.singleUpdate',
        'admin.externalPointConfig.singleFind',
      ])
    )
    const useExternalWallet = computed(() =>
      checkActionList([
        'admin.externalWalletConfig.singleUpdate',
        'admin.externalWalletConfig.singleFind',
      ])
    )
    return {
      useExternalPoint,
      useExternalWallet,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
